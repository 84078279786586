import type { Status } from '#core/types/ui'
import type { Notification } from '#ui/types/notification'
import { defu } from 'defu'
import { capitalize } from 'vue'

let _id = 0

const statusClasses = {
  success: {
    border: 'border-green-600/30',
    icon: 'i-heroicons-check-circle',
    text: 'text-forgd-green-600',
    bg: 'bg-forgd-green-600/10',
  },
  info: {
    border: 'border-primary-300/30',
    icon: 'i-heroicons-information-circle',
    text: 'text-forgd-primary-300',
    bg: 'bg-neutral-600/30',
  },
  warning: {
    border: 'border-yellow-700/30',
    icon: 'i-heroicons-exclamation-triangle',
    text: 'text-forgd-yellow-700',
    bg: 'bg-forgd-yellow-700/10',
  },
  error: {
    border: 'border-red-600/30',
    icon: 'i-heroicons-exclamation-circle',
    text: 'text-forgd-red-600',
    bg: 'bg-forgd-red-600/10',
  },
  neutral: {
    border: 'border-slate-600/30',
    icon: 'i-heroicons-information-circle',
    text: 'text-slate-600',
    bg: 'bg-slate-600/10',
  },
}

function makeOptions(type: Status, options: Partial<Notification>): Notification {
  // variables
  const { text, bg, icon } = statusClasses[type]
  const { title, description } = options

  // options
  return defu({
    icon,
    id: String(_id++),
    title: title || capitalize(type),
    ui: defineUi('notification', {
      container: `${bg} space-y-2`,
      background: `bg-forgd-bgd-200 rounded-lg`,
      title: `text-sm text-forgd-gray-600 ${title && description ? 'font-semibold' : ''}`,
      description: 'text-sm text-forgd-gray-600',
      rounded: 'rounded-md',
      ring: 'ring-0',
      icon: {
        color: `${text}`,
      },
      progress: {
        base: 'hidden',
      },
    }),
    closeButton: {
      ui: defineUi('button', {
        base: '-m-0.5 p-0.5 hover:bg-black/5',
        rounded: 'rounded-md',
        icon: {
          base: 'size-5',
        },
      }),
    },
  }, options) as Notification // casting as closeButton apparently incompatible (but takes effect)
}

/**
 * Show application-level notifications
 *
 * @see https://github.com/forged-com/forgd/pull/1715
 */
export function useAppToast() {
  // this may be getting called after the nuxt app context is lost
  const nuxtApp = tryUseNuxtApp()
  if (!nuxtApp) {
    // noop
    const noopHandler = () => {
      if (import.meta.dev) {
        throw new Error('useAppToast() must be called before an async context.')
      }
    }
    return {
      success: noopHandler,
      info: noopHandler,
      warning: noopHandler,
      error: noopHandler,
    }
  }
  const toast = useToast()

  function add(type: Status) {
    return (options: string | Partial<Notification>) => {
      const userOptions: Partial<Notification> = typeof options === 'string'
        ? { title: options }
        : options
      const toastOptions = makeOptions(type, userOptions)
      toast.add(toastOptions)
    }
  }

  return {
    success: add('success'),
    info: add('info'),
    warning: add('warning'),
    error: add('error'),
  }
}
